<template>
  <div>
    <WorkRate
      :rates="rates"
      :skills="skills"
      @saveRate="saveRate"
      @createRate="createRate"
      @deleteRate="deleteRate"
      :delete="true"
      :create="true"
      title="Role"
      rate="Assignee cost"
      margin="Markup"
      rateTotal="Sales cost"
      ref="removeNewLine"></WorkRate>
    <v-snackbars
        :messages.sync="messages"
        color="red"
        timeout="2000"
        multi-line
        max-width="350"
        left
        rounded="lg"
        light
        elevation="4"
        content-class="snackbar"
      >
      <template v-slot:default="{ message }">
        <strong>{{ message.title }}</strong>
        <p>{{ message.text }}</p>
        <v-row v-if="message.title === 'Error'" class="ma-0 justify-end">
          <v-btn
            class="rounded-lg fz-12 fw-600 text-none border-none"
            outlined
            height="26"
            elevation="0"
            @click="doCopy('Error', message.text)"
          >Copy</v-btn>
        </v-row>
      </template>
    </v-snackbars>
    <div class="preloader" v-if="loading">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import WorkRate from '@/components/App/Plans/WorkRate'
import gql from 'graphql-tag'
import VSnackbars from 'v-snackbars'

export default {
  components: { WorkRate, 'v-snackbars': VSnackbars },
  data () {
    return {
      rates: [],
      snackbar: false,
      loading: false,
      messages: [],
      skills: []
    }
  },
  methods: {
    async allRoleRates () {
      this.loading = true
      await this.$apollo.query({
        query: gql`query PlansWorkRate {
          pricing {
            allRoleRates {
              id
              margin
              roleName
              skillLevel {
                  name
                  id
                }
              valueMoney
              valueMoneyWithMargin
              value
              readOnly
            }
          }
        }`,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.rates = data.data.pricing.allRoleRates
        this.loading = false
        this.allSkills()
      }).catch((err) => {
        this.addSnack('item.name', 'Error', err)
        this.loading = false
        this.allSkills()
        console.log(err)
      })
    },
    async allSkills () {
      await this.$apollo.query({
        query: gql`query AllSkills {
          team {
            allSkillLevels {
              id
              name
            }
          }
        }`
      }).then((data) => {
        this.skills = data.data.team.allSkillLevels
      }).catch((err) => {
        this.addSnack('item.name', 'Error', err)
        console.log(err)
      })
    },
    async saveRate (rate) {
      (await this.$apollo.mutate({
        mutation: gql`mutation PlansWorkRateUpdate($input: TeamRoleRateUpdateInput! ) {
          pricing {
            teamRoleRate {
              update(input: $input) {
                id
                margin
                roleName
                skillLevel {
                  name
                  id
                }
                valueMoney
                valueWithMargin
                valueMoneyWithMargin
              }
            }
          }
        }`,
        // Parameters
        variables: {
          input: {
            value: { amount: rate.valueMoney.amount, currency: rate.valueMoney.currency },
            id: rate.id,
            roleName: rate.roleName,
            margin: rate.margin
          }
        }
      })
        .then(() => {
          this.allRoleRates()
          this.addSnack(rate.roleName, 'Success', rate.roleName + ' was updated with success')
        })
        .catch((err) => {
          console.log(err)
          this.addSnack('item.name', 'Error', err)
        }))
    },
    async createRate (item) {
      (await this.$apollo.mutate({
        mutation: gql`mutation PlansWorkRateCreate($input: TeamRoleRateCreateInput! ) {
          pricing {
            teamRoleRate {
              create(input: $input) {
                id
                margin
                roleName
                skillLevel {
                  name
                  id
                }
                valueMoney
                valueMoneyWithMargin
              }
            }
          }
        }`,
        // Parameters
        variables: {
          input: {
            value: { amount: item.money, currency: 'RUB' },
            roleName: item.roleName,
            margin: item.margin,
            skillLevelId: 1 // DEFAULT JUNIOR
          }
        }
      })
        .then((data) => {
          this.addSnack(item.name, 'Success', item.name + ' was updated with success')
          this.rates.push(data.data.pricing.teamRoleRate.create)
          this.$refs.removeNewLine.removeNewLine()
        })
        .catch((err) => {
          console.log(err)
          this.addSnack('item.name', 'Error', err)
        }))
    },
    async deleteRate (item) {
      (await this.$apollo.mutate({
        mutation: gql`mutation PlansWorkRateDelete($id: ID!) {
          pricing {
            teamRoleRate {
              delete(id: $id)
              __typename
            }
            __typename
          }
        }`,
        // Parameters
        variables: {
          id: item.id
        }
      })
        .then(() => {
          this.addSnack(item.roleName, 'Success', item.roleName + ' was deleted with success')
          const index = this.rates.indexOf(this.rates.find(el => el.id === item.id))
          this.rates.splice(index, 1)
        })
        .catch((err) => {
          this.addSnack('item.name', 'Error', err)
        }))
    },
    addSnack (item, result, text) {
      this.messages.push({ title: result, text: text })
    },
    doCopy (action, value) {
      const vm = this
      this.$copyText(value).then(function () {
        vm.addSnack(value, 'Success', action + ' text copied')
      }, function (e) {
        console.log(e)
      })
    }
  },
  created () {
    this.allRoleRates()
  }
}
</script>

<style scoped>

</style>
