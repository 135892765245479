<template>
  <v-text-field
    :value="focus ? Math.round(value * 100) / 100 : Math.round(value * 100) / 100 + '%'"
    @focus="focus = true"
    @blur="focus = false; $emit('blur', $event)"
    @keyup="$emit('keyup', $event)"
    solo
    flat
    :rules="[rules.required, rules.positive]"
    class="fz-14 moveup-details"
  ></v-text-field>
</template>

<script>
export default {
  name: 'inputPercentTransparent',
  props: ['value'],
  data() {
    return {
      focus: false,
      rules: {
        required: value => (value !== null && String(value).length > 0) || 'Required.',
        positive: value => Number.parseFloat(value) >= 0 || 'Required positive number',
        minCounter: value => value.trim().length >= 2 || 'Min 2 characters'
      }
    }
  }
}
</script>

<style scoped>

</style>
