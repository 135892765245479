<template>
  <v-col class="mx-0 px-7 my-3">
    <v-row class="ma-0 title height-50">
      <div class="role-input-width mt-auto px-3"><p class="fz-14 font-weight-bold">{{ title }}</p></div>
      <div class="width-15 mt-auto px-3"><p class="fz-14 font-weight-bold">{{ rate }}</p></div>
      <div class="width-15 mt-auto px-3"><p class="fz-14 font-weight-bold">{{ margin }}</p></div>
      <div class="width-15 mt-auto px-3"><p class="fz-14 font-weight-bold">{{ rateTotal }}</p></div>
    </v-row>
    <v-form ref="form">
      <v-row v-for="rate in rates" :key="rate.id" class="ma-0 height-50">
        <v-col>
          <v-row>
            <div class="role-input-width border-l-none border-b-none border-lightgray height-50">
              <v-text-field
                v-model="rate.roleName"
                @keyup="updateLine($event, rate)"
                @blur="editNow($event, rate)"
                solo
                flat
                :readonly="rate.readOnly"
                :rules="[rules.required, rules.minCounter]"
                class="fz-14 moveup-details"
              ></v-text-field>
            </div>
            <div class="width-15 border-l-none border-b-none height-50 border-lightgray">
              <v-text-field
                :value="Math.round(rate.valueMoney.amount * 100) / 100"
                @blur="editRateNow($event, rate)"
                @keyup="updateRate($event, rate)"
                solo
                flat
                :rules="[rules.required, rules.positive]"
                class="fz-14 moveup-details prepend-currency"
              >
                <template v-slot:prepend-inner>
                  <span class="text-black">{{ rate.valueMoney.symbol }}</span>
                </template>
              </v-text-field>
            </div>
            <div class="width-15 border-l-none border-b-none height-50 border-lightgray">
              <!--              <v-text-field-->
              <!--                :value="focusMargin ? Math.round(rate.margin * 100) / 100 : Math.round(rate.margin * 100) / 100 + '%'"-->
              <!--                @focus="focusMargin = true"-->
              <!--                @blur="focusMargin = false; editMarginNow($event, rate)"-->
              <!--                @keyup="updateMargin($event, rate)"-->
              <!--                solo-->
              <!--                flat-->
              <!--                :rules="[rules.required, rules.positive]"-->
              <!--                class="fz-14 moveup-details"-->
              <!--              ></v-text-field>-->
              <input-percent-transparent :value="rate.margin" @blur="(event) => editMarginNow(event, rate)"
                                         @keyup="(event) => updateMargin(event, rate)"/>
            </div>
            <div class="width-15 border-l-none border-b-none border-r-none height-50 border-lightgray">
              <v-text-field
                :value="rate.valueMoneyWithMargin.symbol + (Math.round(rate.valueMoneyWithMargin.amount * 100) / 100)"
                hide-details
                solo
                readonly
                flat
                class="fz-14 moveup-details"
              ></v-text-field>
            </div>
            <div class="border-lightgray border-b-none border-r-none width-50 height-50" style="min-width: 50px">
              <v-dialog
                transition="dialog-bottom-transition"
                max-width="370"
                content-class="rounded-xl"
                overlay-color="rgba(9, 24, 73, 0.2)"
                overlay-opacity="1"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="!rate.readOnly"
                    v-bind="attrs"
                    v-on="on"
                    elevation="0"
                    small
                    icon
                    fab
                    class="height-50 width-50 mx-auto"
                    style="min-width: 49px"
                  >
                    <v-icon size="20" class="stroke-gray">$delete</v-icon>
                  </v-btn>
                </template>
                <template v-slot:default="dialog">
                  <v-card class="pa-7">
                    <v-card-text class="py-0">
                      <div class="fz-18 text-center text-black">Do you really want to delete {{ rate.roleName }}?</div>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="justify-space-between pa-0">
                      <v-btn
                        class="rounded-lg text-none fz-16 fw-600"
                        outlined
                        width="150"
                        height="40"
                        @click="dialog.value = false"
                      >Return
                      </v-btn>
                      <v-btn
                        elevation="0"
                        color="#FF0000"
                        width="150"
                        height="40"
                        class="rounded-lg white--text text-none fz-16 fw-600"
                        @click="removeLine(rate)"
                      >
                        <v-icon left class="stroke-white">
                          $delete
                        </v-icon>
                        Delete
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <v-form ref="formNewLine">
      <v-row v-if="newLine.show" class="ma-0 height-50">
        <!--RoleName-->
        <div class="role-input-width border-b-none border-lightgray border-l-none height-50 fz-14">
          <v-text-field
            v-model="newLine.roleName"
            solo
            flat
            :rules="[rules.required, rules.minCounter]"
            class="fz-14 moveup-details"
          ></v-text-field>
        </div>
        <!--money-->
        <div class="width-15 height-50 border-b-none border-l-none border-lightgray">
          <v-text-field
            v-model="newLine.money"
            solo
            flat
            :rules="[rules.required, rules.positive]"
            class="fz-14 moveup-details"
          ></v-text-field>
        </div>
        <!--margin-->
        <div class="width-15 height-50 border-b-none border-l-none border-lightgray">
          <v-text-field
            v-model="newLine.margin"
            solo
            flat
            :rules="[rules.required, rules.positive]"
            class="fz-14 moveup-details"
          ></v-text-field>
        </div>
        <!--RoleName-->
        <div class="width-15 height-50 border-b-none border-r-none border-l-none border-lightgray">
          <v-text-field
            solo
            flat
            readonly
            class="fz-14 moveup-details"
          ></v-text-field>
        </div>
        <!--Delete-->
        <div class="border-lightgray border-b-none border-r-none width-50 height-50" style="min-width: 50px">
          <v-btn
            @click="removeNewLine"
            elevation="0"
            small
            icon
            fab
            class="height-50 width-50 mx-auto"
            style="min-width: 49px"
          >
            <v-icon size="20" class="stroke-gray">$delete</v-icon>
          </v-btn>
        </div>
      </v-row>
    </v-form>
    <v-row v-if="createVal" class="border-lightgray border-l-none border-r-none ma-0">
      <v-btn
        height="50"
        text
        tile
        color="#7552CC"
        :disabled="newLine.show"
        @click="newLine.show = true"
        class="text-none px-3 fz-14 font-weight-medium">
        <b class="fz-16 fw-600 mr-1"> + </b>Add new line
      </v-btn>
      <v-btn
        v-if="newLine.show"
        height="50"
        text
        tile
        color="#7552CC"
        @click="saveNewLineNow"
        class="text-none px-3 fz-14 font-weight-medium"
      >
        Save new line
      </v-btn>
    </v-row>
    <v-row v-else class="border-lightgray border-b-none border-l-none border-r-none ma-0">
    </v-row>
  </v-col>
</template>

<script>
import _ from 'lodash'
import InputPercentTransparent from '../../inputs/inputPercentTransparent'

const saveNewLine = _.debounce(function () {
  const vm = this
  if ((vm.newLine.roleName.trimEnd()) && (vm.newLine.margin.trimEnd()) && (vm.newLine.money.trimEnd())) {
    vm.$emit('createRate', vm.newLine)
  }
}, 2000)
const editLine = _.debounce(function (item) {
  this.$emit('saveRate', item)
}, 1500)

export default {
  components: { InputPercentTransparent },
  props: ['rates', 'delete', 'create', 'title', 'rate', 'margin', 'rateTotal', 'skills'],
  data() {
    return {
      focus: false,
      focusMargin: false,
      newLine: {
        show: false,
        roleName: '',
        margin: '',
        money: ''
      },
      rules: {
        required: value => (value !== null && String(value).length > 0) || 'Required.',
        positive: value => Number.parseFloat(value) >= 0 || 'Required positive number',
        minCounter: value => value.trim().length >= 2 || 'Min 2 characters'
      }
    }
  },
  computed: {
    deleteVal() {
      return this.delete
    },
    createVal() {
      return this.create
    }
  },
  methods: {
    removeLine(item) {
      this.$emit('deleteRate', item)
    },
    removeNewLine() {
      this.newLine.show = false
      this.newLine.roleName = ''
      this.newLine.margin = ''
      this.newLine.money = ''
    },
    editNow(event, rate) {
      if (this.$refs.form.validate()) {
        if (event.target.value !== rate.roleName) {
          rate.roleName = event.target.value
          this.$emit('saveRate', rate)
          editLine.cancel()
        }
      }
    },
    editRateNow(event, rate) {
      if (this.$refs.form.validate()) {
        if (event.target.value !== rate.valueMoney.amount + '' && event.target.value !== rate.valueMoney.amount + rate.valueMoney.symbol) {
          rate.valueMoney.amount = Number.parseFloat(event.target.value)
          this.$emit('saveRate', rate)
          editLine.cancel()
        }
      }
    },
    editMarginNow(event, rate) {
      if (this.$refs.form.validate()) {
        if (event.target.value !== rate.margin + '' && event.target.value !== rate.margin + '%') {
          rate.margin = event.target.value
          this.$emit('saveRate', rate)
        }
      }
    },
    saveNewLineNow() {
      this.newLine.show = false
      const vm = this
      if ((vm.newLine.roleName.trimEnd()) && (vm.newLine.margin.trimEnd()) && (vm.newLine.money.trimEnd())) {
        vm.$emit('createRate', vm.newLine)
        saveNewLine.cancel()
      }
    },
    saveNewLine,
    editLine,
    updateLine(event, rate) {
      if (this.$refs.form.validate()) {
        if (event !== rate.roleName) {
          rate.roleName = event.target.value
          this.editLine(rate)
        }
      }
    },
    updateRow(rate) {
      this.editLine(rate)
    },
    updateRate(event, rate) {
      if (this.$refs.form.validate()) {
        if (event.target.value !== rate.money + '' && event.target.value !== rate.valueMoney.amount + ' ' + rate.valueMoney.currency) {
          rate.valueMoney.amount = Number.parseFloat(event.target.value)
          this.editLine(rate)
        }
      }
    },
    updateMargin(event, rate) {
      if (this.$refs.form.validate()) {
        if (event.target.value !== rate.margin + '') {
          rate.margin = Number.parseFloat(event.target.value)
          this.editLine(rate)
        }
      }
    }
  }
}

</script>

<style scoped lang="scss">
.role-input-width {
  width: calc(55% - 55px);
}

.border-lightgray {
  border: 1px solid #EEF2F7;
}
</style>
